import storage from 'redux-persist/lib/storage'; //fixme fixed! good storage
import borderdata from './borderdata'
import {isEmpty} from "lodash";
//
//late v1 configs, to rethink, dirty now

export const builtOnPostfix = '29-12-22/b';
//export const builtOnPostfix = '02-07-20/a/trtdm';
export const patchVersion = builtOnPostfix; //+'/patch10';
//export const builtOn = '1.2/15-05-20/a/demo';
//export const patchVersion = builtOn; //+'/fixmeTo patched 1.2/15-05-20/a/demo with switches';
export const devBuild = true;
export const showZonesExtras = true;

//hotfixing pandoras bandwith issue 1.1
export const bandwidthIssues = true;

export const FEATURES_V1_2_ENUMS = {
    ACM: 'ACM',
    OPERATOR_PRIVILIGES: 'OPERATOR_PRIVILIGES',
    INFOBOX_BLINKS: 'INFOBOX_BLINKS',
    INFOBOX_NEW_MISSION_INFOS: 'INFOBOX_NEW_MISSION_INFOS',
    // NOTICE: enables also features connected with APPLICATION_SHOW_VIEW_WITH_PRINCIPAL
    // NOTICE: adds new view - CAA messages block
    ALERTBOX_NEW_INFOS: 'ALERTBOX_NEW_INFOS',
    MISSION_DROPDOWN_SUPPORTED_TYPES: 'MISSION_DROPDOWN_SUPPORTED_TYPES',
    MISSION_STATUS_TRANSLATIONS: 'MISSION_STATUS_TRANSLATIONS',
    ZONE_DELETE: 'ZONE_DELETE',
    ASM_NEW_LABELS: 'ASM_NEW_LABELS',
    UNIT_CREATE_NEW_ENDPOINT: 'UNIT_CREATE_NEW_ENDPOINT',
    APPLICATION_SHOW_VIEW_WITH_PRINCIPAL: 'APPLICATION_SHOW_WITH_PRINCIPAL',
    UNIT_SHOW_JURISDICTION: 'UNIT_SHOW_JURISDICTION',
    OPERATION_CANCEL_IN_CTR: 'OPERATION_CANCEL_IN_CTR',
    MISSION_112: 'MISSION_112',
    ACCOUNT_VIEW_SMOOTHER_UX: 'ACCOUNT_VIEW_SMOOTHER_UX',
    ACM_INFOBOXES: 'ACM_INFOBOXES',
    CALLSIGN_ENGINE_STATIC: "CALLSIGN_ENGINE_STATIC",
    APPLICATION_NEW_GOV_USER_MSGS: "APPLICATION_NEW_GOV_USER_MSGS",
    AEROBITS_RTDM_STREAM_WIP: 'AEROBITS_RTDM_STREAM',
    ATTN_SPLIT_WIP: 'ATTN_SPLIT',
    CUSTOM_RT_MAP_OPS: 'CUSTOM_RT_MAP_OPS',
};

export const enabledFeatures = [
    FEATURES_V1_2_ENUMS.ACM,
    FEATURES_V1_2_ENUMS.OPERATOR_PRIVILIGES,
    FEATURES_V1_2_ENUMS.INFOBOX_BLINKS,
    FEATURES_V1_2_ENUMS.INFOBOX_NEW_MISSION_INFOS,
    FEATURES_V1_2_ENUMS.MISSION_DROPDOWN_SUPPORTED_TYPES,
    FEATURES_V1_2_ENUMS.ZONE_DELETE,
    FEATURES_V1_2_ENUMS.ASM_NEW_LABELS,
    FEATURES_V1_2_ENUMS.UNIT_CREATE_NEW_ENDPOINT,
    FEATURES_V1_2_ENUMS.APPLICATION_SHOW_VIEW_WITH_PRINCIPAL,
    FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS,
    FEATURES_V1_2_ENUMS.MISSION_STATUS_TRANSLATIONS,
    FEATURES_V1_2_ENUMS.UNIT_SHOW_JURISDICTION,
    FEATURES_V1_2_ENUMS.OPERATION_CANCEL_IN_CTR,
    FEATURES_V1_2_ENUMS.MISSION_112,
    FEATURES_V1_2_ENUMS.ACCOUNT_VIEW_SMOOTHER_UX,
    FEATURES_V1_2_ENUMS.ACM_INFOBOXES,
    FEATURES_V1_2_ENUMS.CALLSIGN_ENGINE_STATIC,
    FEATURES_V1_2_ENUMS.APPLICATION_NEW_GOV_USER_MSGS,
    FEATURES_V1_2_ENUMS.ATTN_SPLIT_WIP,
    FEATURES_V1_2_ENUMS.CUSTOM_RT_MAP_OPS
    //FEATURES_V1_2_ENUMS.AEROBITS_RTDM_STREAM_WIP, //do not enable until finished - only trtdm early test now
];

export const version = isEmpty(enabledFeatures) ? "1.1" : "1.2";
export const builtOn = `${version}/${builtOnPostfix}`;

//FIXME bug support required on app, so temp switch here
export const NOTAM_DEACTIVATION_SUPPORT = true;
//FIXME change in behaviour requires accept from PANSA. 1.1? 1.2?
export const NEW_PANDORA_TIMEOUTS_BEHAVIOUR = true;


export const COUNTRIES_ENUMS = {
    PL : 'PL'
};

//should be on server
export const COUNTRIES_DATA = {
    "PL": {
        "key" : "PL",
        "aupZuluStartHour": "06:00",
        "countryIntlPrefix": "48",
        "countryTimezone": "Europe/Warsaw",
        viewportData:{
            "center":[19.356389, 52.196667],
            //"center":[18.027, 53.019],
            "zoom":5
        },
        borderData:borderdata
    }
};

export const defaultCountry = COUNTRIES_DATA[COUNTRIES_ENUMS.PL];

export const defaultLocale = 'pl'; //temp solution, we need buttons, -> done, to cleanup

export const APP_MODES = {
    GOV: 'GOV',
    OPERATOR: 'OPERATOR',
    MOBILE: 'MOBILE'
};

export const Apps = {
    RT_MAP:{name:'RT_MAP',type:APP_MODES.GOV,
        oldZonesSetup: [ //ordering reverted from original!
            "jswi",
            "atz",
            "atz1km",
            "matz",
            "matz2km",
            "fis",
            "ctr",
            "ctr6km",
            "custom_rpa",
            "ctr1km",
            "rol",
        ],
        callsignEngineStatic: enabledFeatures.includes(FEATURES_V1_2_ENUMS.CALLSIGN_ENGINE_STATIC)
    },
    DR2_GOV:{name:'DR2_GOV',type:APP_MODES.GOV},
    DR2_OP:{name:'DR2_OP',type:APP_MODES.OPERATOR, maxAttnSize:10**7},
    CHECKINFLOW:{name:'CHECKINFLOW',type:APP_MODES.MOBILE},
    MOBILE:{name:'MOBILE',type:APP_MODES.MOBILE}

};

//liveADSB url setup
const liveAdsbBaseUrl = "https://global.adsbexchange.com/VirtualRadar/AircraftList.json";
const fNBnd = 55.5;
const fEBnd = 25;
const fSBnd = 48.5;
const fWBnd = 13;
const MAP_SIZE = 1000 // in px
//&trFmt=fa

const v2Server = getEnv('V2_SERVER')
const v1Server = 'https://v1Server.eu';
//const v1Server = 'https://tdr.azure.pansa.pl';
//const v1Server = 'https://tsrtm.azure.pansa.pl';

const apiBaseV2 = v2Server+"/v3";

export const urls = {
    apiBaseV2,

    loopbackVersionUrl:apiBaseV2+'/version.json',

    socketV1Url: {

        DEV:v1Server,

        PROD:'https://v1Server.eu'
    }, //used for io socket!ls
    socketV1Path: {
        GOV: "/rt-map/socket.io",
        //GOV: "/alerts/socket.io",
        //GOV: "/socket.io" //old nginx setup!
        //OPERATOR: "/rt-map/alerts/socket.io"
        OPERATOR: "/alerts/socket.io",
    },

    socketV2Url : v2Server,
    socketV2Path: '/v3/socket.io',
    //socketV2Path: '/socket.io', //local dev
    //api_key is fake (not implemented on server side), but will help to filter out who requests
    //srtmUrl : 'https://whatiselevation.com/srtm?lat=53.108&lon=17.985
    getSrtmUrl: {
        RT_MAP: "https://whatiselevation.psrtm.azure.pansa.pl/srtm?api_key=RT_MAP2",
        DR2_GOV: "https://whatiselevation.psrtm.azure.pansa.pl/srtm?api_key=DR2_GOV",
        DR2_OP: "https://whatiselevation.psrtm.azure.pansa.pl/srtm?api_key=DR2_OP",
        MOBILE: "https://whatiselevation.psrtm.azure.pansa.pl/srtm?api_key=MOBILE",
        CHECKINFLOW: "https://whatiselevation.psrtm.azure.pansa.pl/srtm?api_key=CHECKINFLOW",
    },

    getPandoraUserUrl: v1Server+'/rt-map/api/v1/atm/user',

    //aargh, duplicating some endpoints, no RA in mobile
    createAnonOperator: apiBaseV2+'/Operators/anonymousOperator',
    operatorLogin : apiBaseV2+'/Operators/login',
    operatorLoadSelf : apiBaseV2+'/Operators/{userId}',
    operatorSendCode2Phone: apiBaseV2+'/Operators/send-code',
    operatorVerifyPhoneCode: apiBaseV2+'/Operators/verify-code',
    operatorPostNewCheckin: apiBaseV2 + '/Operators/{userId}/operations',
    govPatchCheckin: apiBaseV2 + '/Govs/operations/{modelId}',
    getZoneTypesV2Url: apiBaseV2+'/ZoneTypes',

    ///argh no react admin for mobile.
    getZonesV2Url : apiBaseV2+'/Zones',

    getRtMapMissions : apiBaseV2+'/Govs/missions',

    lprHelipadsUrls: {
        RT_MAP: v1Server+'/rt-map/api/v1/lprhems',
        DR2_GOV: v1Server+'/rt-map/api/v1/lprhems',
        DR2_OP: v1Server+'/rt-map/api/v1/lprhems',
    },

    //fixme?, move to resources/rest urls.. but later
    getWebcatActivactionsUrl: apiBaseV2+'/WebcatActivations',
    getAixmTimeslicesUrl: apiBaseV2+'/AixmTimeslices',
    getNotamsUrl: apiBaseV2+'/Notams',
    getNotamActivactionsUrl: apiBaseV2+'/NotamActivations',

    reqOperatorDashboardUrl: apiBaseV2+'/Operators/{userId}/operatorDashboard', //post!
    reqGovDashboardUrl: apiBaseV2+'/Govs/govDashboard', //post!

    getAupV1Url: v1Server+'/json/zones/aup',
    getAipV1Url: v1Server+'/json/zones/aip',

    //fix new(?) aggregation sql from jasiek!, compare with app mobile (shouldn;t be used anyway)

    oldZonesUrl : v1Server+'/rt-map/api/v1/zone',

    // //yes, currently the same endpoint as operator, to compare if ok
    alertsV1Urls: {
        RT_MAP: v1Server+"/json/zones/atm_alerts",
        DR2_GOV: v1Server+"/json/zones/atm_alerts",
        DR2_OP: v1Server+"/json/zones/atm_alerts",
        MOBILE: v1Server+"/json/zones/atm_alerts",
        CHECKINFLOW: v1Server+"/json/zones/atm_alerts",
    },

    alertsV2Urls: {
        RT_MAP: apiBaseV2+"/Alerts",
        DR2_GOV: apiBaseV2+"/Alerts",
        DR2_OP: apiBaseV2+"/Alerts",
        MOBILE: apiBaseV2+"/Alerts",
        CHECKINFLOW: apiBaseV2+"/Alerts",
    },

    checkinsUrls: {
        RT_MAP: v1Server+"/rt-map/api/v1/atm/operations",
        DR2_GOV: v1Server+"/json/checkin",
        DR2_OP: v1Server+"/json/checkin",
        MOBILE: v1Server+"/json/checkin",
        CHECKINFLOW: v1Server+"/json/checkin",
    },

    //fixme!! some unauthorized now
    checkinsV2Urls: {
        RT_MAP: apiBaseV2+"/Govs/Operations",
        RT_MAP_CUSTOM: apiBaseV2+"/Govs/operations/rtMapView",
        //DR2_GOV: v1Server+"/json/checkin",
        //DR2_OP: v1Server+"/json/checkin",
        //MOBILE: apiBaseV2+"/Operations",
        //CHECKINFLOW: apiBaseV2+"/Operations",
    },


    //rtdmCreotechTicksUrl : 'https://airspac.eu/adsb-sources/showDebug',
    rtdmCreotechTicksUrl : 'https://adsb.trtdm.azure.pansa.pl/adsb-sources/getCreotechV2AdsbTicks?max=15000', //hardcoded max val reducing bandwidth
    rtdmExchangeTicksUrl : 'https://adsb.prtdm.azure.pansa.pl/adsb-sources/getADSBXchangeTicks',
    rtdmExchangeAircraftsUrl : 'https://adsb.prtdm.azure.pansa.pl/adsb-sources/getADSBXchangeAircrafts',

    rtdmSoapLprUnitsUrl : 'https://adsb.prtdm.azure.pansa.pl/adsb-sources/getSoapLprUnitsTicks',
    rtdmAerobitsUrl : 'https://adsb.prtdm.azure.pansa.pl/adsb-sources/getAerobitsTicks', //RTT!!

    //2 review and reimplement!
    adsbDroniadaTicksUrl : 'https://adsb.prtdm.azure.pansa.pl/adsb-sources/getADSBDroniadaTicks',
    adsbDroniadaSocketUrl : 'ws://vps467139.ovh.net:1337',

    liveAdsbUrl : `${liveAdsbBaseUrl}?fNBnd=${fNBnd}&fEBnd=${fEBnd}&fSBnd=${fSBnd}&fWBnd=${fWBnd}`,

    //buu (moved to rest config, cleanup)
    //POST /Operators/{missionid}/legs
    createLegUrl: "",
    //PUT /Operators/{legid}/legs
    updateLegUrl: "",
    updateLegMethod : "PUT",

    readZoneUrl: "",
    updateZoneUrl : "",
    updateZoneMethod : "PUT",

    readMissionUrl: "",
    updateMissionUrl: "",

    notamUtil: window.origin + "/notam-util/",
    //notamUtilLocal: "http://192.168.0.18/notam-util",
    notamUtilLocal: "http://localhost:4300",
};

// fixme -> add production urls, when revieved
const prodUrls = {

};

export const storages = {
    default: storage,
};

export default {
    builtOn,
    devBuild,
    COUNTRIES_ENUMS,
    defaultCountry,
    Apps,
    urls,
    APP_MODES,
    storages,
    enabledFeatures,
    apiBaseV2,
    MAP_SIZE
};

export function getEnv(envName, defaultV=undefined) {
    if(window._env_ && window._env_[envName]) {
        return window._env_[envName];
    }
    
    const envValue = process.env[`REACT_APP_${envName}`];
    if(envValue)
        return envValue;

    if(defaultV !== undefined)
        return defaultV;

    console.error(`ENV ${envName} is empty!`)
}