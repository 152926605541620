import polishMessages from './ra-language-polish';
import {RESOURCES} from "../logic/MyRestConfig";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";


const INFOBOX_NEW_MISSION_INFOS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.INFOBOX_NEW_MISSION_INFOS);
const MISSION_DROPDOWN_SUPPORTED_TYPES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_DROPDOWN_SUPPORTED_TYPES);
const ASM_NEW_LABELS = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ASM_NEW_LABELS);
const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);
const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);
const ACM_INFOBOXES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACM_INFOBOXES);
const APPLICATION_NEW_GOV_USER_MSGS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_NEW_GOV_USER_MSGS);

export const messages = {
    simple: {
        not_available: 'N/A',
        action: {
            resetViews: 'Resetowanie widoków',
        }
    },
    ...polishMessages,
    resources: {

        defaults : { // do not use.. different languages -> different contexts
        },
        archives : {
            archiveLabel : 'Archiwum',
            activeLabel : 'Aktywne',

            action: {
                archive: 'Archiwizuj',
                unarchive: 'Odzyskaj'
            },
            notifications: {
                archived:  'Zarchiwizowano pomyślnie',  //hmm (was smart count)
                unarchived: 'Przywrócono pomyślnie'
            }
        },
        operations : {
            notifications: {
                saveFailedErr: 'Błąd zapisu operacji',
            },
        },
        applications: {
            status: {
                accepted: 'Wniosek zweryfikowany |||| Wnioski zweryfikowane',
                rejected: 'Wniosek odrzucony |||| Wnioski odrzucone',
                pending: 'Wniosek oczekuje na weryfikację |||| Wnioski oczekujące na weryfikacje',
                unknown: 'Wniosek zapisany |||| Wnioski zapisane',
                pendingAgain: 'Wniosek oczekuje na ponowną weryfikację |||| Wnioski oczekujące na ponowną weryfikacje',
                modified: "Wniosek odrzucony i zmodyfikowany przez operatora |||| Wnioski odrzucone i zmodyfikowane przez operatora",
                created: "Bez weryfikacji",
                meetingRequest: "Spotkanie oczekuje na akceptację operatora |||| Spotkania oczekujące na akceptację operatora",
                meetingAccepted: "Potwierdzone spotkanie |||| Potwierdzone spotkania",
                meetingRejected: "Odrzucone spotkanie |||| Odrzucone spotkania"

            },
            resource: "Zasób |||| Zasoby",
            loading: 'Ładowanie wniosków',
            name: 'Nazwa wniosku',
            verification: "Weryfikacja",
            archive: 'Zarchiwizowany',
            verify: "Weryfikuj",
            ...(NEW_ALERTBOX_ENABLED && {verificationStatus: 'Status weryfikacji'}),
            ...(NEW_ALERTBOX_ENABLED && {messagesCaa: 'Wiadomości od CAA'}),
            ...(NEW_ALERTBOX_ENABLED && {messagesPrincipal: 'Wiadomości od wnioskującego'}),
            accept: "Zatwierdź wniosek",
            archiveDisabled: "Nie można archiwizować w trakcie weryfikacji",
            reject: "Odrzuć wniosek",
            createMeeting: "Utwórz spotkanie",
            confirmMeeting: "Potwierdź spotkanie",
            denyMeeting: "Odrzuć spotkanie",
            cancelMeeting: "Odwołaj spotkanie",
            meeting: "Spotkanie |||| Spotkania",
            kind: "Rodzaj",
            notifications: {
                applicationSentMsg: 'Wysłano wniosek do weryfikacji',
                applicationSentFailMsg: 'Nie udało się wysłać wniosku do weryfikacji',
                applicationSending: 'Wysyłanie wniosku do weryfikacji',
                applicationChangeStatusMsg: 'Status wniosku został zaktualizowany',
                applicationChangeStatusFailMsg: 'Nie udało się zaktualizować statusu wniosku',
                applicationChangingStatus: 'Aktualizacja statusu wniosku'
            },
            rejectDialogTitle: 'Wniosek odrzucony',
            rejectDialogDefaultText: 'Powód odrzucenia',
            rejectDialogLabel: 'Dodatkowe informacje o odrzuceniu',
            acceptDialogLabel: 'Dodatkowe informacje o zatwierdzeniu',
            meetingDialogTitle: 'Tworzenie spotkania',
            meetingDialogDefaultText: 'Lokalizacja',
            meetingDialogLabel: 'Dodatkowe informacje o spotkaniu (np. lokalizacja)',
            meetingDate: 'Termin spotkania',
            principalDialogTitle: "Wyślij wniosek do weryfikacji",
            principalDialogLabel: "Wybierz wnioskodawcę",
            principal: "Wnioskujący",
            verificationDialogTitle: "Czy chcesz utracić weryfikację?",
            verificationDialogLabel: "Zapisanie zmian spowoduję utratę weryfikacji. Czy jesteś pewien?",
            rejectMeetingDialogTitle: 'Odrzuć spotkanie',
            rejectMeetingDialogLabel: 'Powody odrzucenia spotkania',
            rejectMeetingDates: 'Podaj co najmniej jedną alternatywną propozycję terminu spotkania: ',
            rejectMeetingDate: 'Termin #%{number}',
            rejectMeetingDatePropsals: "Propozycje nowych terminów spotkania",
            ...(APPLICATION_NEW_GOV_USER_MSGS_ENABLED && {issuedBy: "Wydano przez"}),
            ...(APPLICATION_NEW_GOV_USER_MSGS_ENABLED ),
        },
        files : {
            attachments : 'Załączniki',
            dropboxLabel : 'Załączniki',
            rejectedInput: 'Nie można wgrać plików. Za duży rozmiar lub zły format! Dopuszczalny rozmiar pliku/plików to 10MB. ' +
                'Dopuszczalne formaty plików to JPEG, PNG, PDF.',
            rejectedInputFromBundleSize: 'Nie można wgrać plików. Przekroczono dopuszczalny rozmiar załączników!',
            dropboxPlaceholder : "Przeciągnij-i-upuść pliki do wgrania, albo kliknij by wybrać z dialogu",
            adding: "Dodawanie załączników"
        },
        droneModels : {
            menu: 'Modele Dronów',
            create: "Nowy model drona",
            name: 'Model Drona |||| Modele Dronów',
            fields: {
                model: 'Model',
                make: 'Producent',
                mtom: 'MTOM (g)',
                performance: 'Osiągi',
                ft: 'Czas lotu (min)',
                vmax: 'VMAX (m/s)',
                vmaxH: 'Vmax w poziomie (m/s)',
                vmaxV: 'Vmax w pionie (m/s)',
                type: 'Typ',
                class: 'Klasa',
                fs: 'Zabezpieczenia',
                category: "Kategoria",
                range: 'Zasięg (m)',
                rangeFollowMe: 'Zasięg w trybie śledzenia (m)',
                drive: "Napęd",
                driveTypes: {
                    electric: "Elektryczny",
                    fuel: "Paliwo ciekłe",
                    hybrid: "Hybrydowy"
                },
                flightModes: "Tryby lotu",
                flightModeTypes: {
                    manual: "Ręczny",
                    gps: "GPS"
                },
                camera: "Kamera",
                lights: "Oświetlenie",
                lightTypes: {
                    navigation: "Nawigacyjne",
                    strobo: "Stroboskopowe"
                },
                maxTakeOffHeight: " Maks. wysokość względem startu (m)",
                bms: "System zarządzania energią (BMS)",
                altitudeSensor: "Sensor wysokości",
                uSpaceIntf: "Interfejs do U-space",
                safeEquipment: "Wyposażenie awaryjne",

            },
            tooltips: {
                vmaxH: 'Maksymalna prędkość w poziomie',
                vmaxV: 'Maksymalna prędkość w pionie',
                range: 'Maksymalny zasięg',
                rangeFollowMe: 'Maksymalny zasięg w trybie śledzenia',
                maxTakeOffHeight: "Maksymalna wysokośc lotu względem startu",
                bms: "Pokładowy system zarządzania energią i napięcie zasilania (Battery Management System)",
                altitudeSensor: "Typ sensora wysokości",
                uSpaceIntf: "Typ interfejsu U-space",
                safeEquipment: "Wyposażenie awaryjne, np. spadochron",
                model: "Jeśli dron nie jest seryjny, wpisz DIY",
                mtom: "Maksymalna masa startowa drona gotowego do lotu (Maximum Take-Off Mass)",
                ft: "Całkowity czas lotu",
                fs: "Wybierz cechy drona które podnoszą bezpieczeństwo wykonywanej misji",
                camera: "Prosimy o podanie cech kamery takich jak: typ, rozdzielczość, możliwość transmisji obrazu, itp.",
                lights: "Wybierz rodzaj oświetlenia",
            }

        },
        drones : {
            menu: 'Drony',
            create: 'Nowy Dron',
            name: 'Dron |||| Drony',
            photoSerialNo: "Prosimy załączyć zdjęcie numeru seryjnego drona",
            fields: {
                uid: 'Unikalny identyfikator',
                registry: 'Rejestracja',
                droneModels: 'Modele',
                name: 'Nazwa',
                created: 'Dodany',
                modified: 'Zmodyfikowany',
                etc_icao: 'Kod ICAO',
                etc_call: 'Transponder Call',
                serialNo: 'Numer seryjny',
                remoteId: 'Zdalne ID',
                equipment: 'Wyposażenie',
                mlu: "Podstawowe urządzenie lokalizujące",
                elu: " Awaryjne urządzenie lokalizujące",
                fpm: "Monitorowanie parametrów lotu",
                fps: "Rejestrowanie parametrów lotu",
                tether: "Długość uwięzi (m)",
                customFeatures: "Cechy szczególne",
                limits: "Ograniczenia operacyjne",
                minAge: "Minimalny wiek (lata)",
                impact: "Energia (J)",
                lwa: "Lwa (dBa)",
                certification: "Certyfikacja",
                risk: "Opis ryzyk",
                photo: "Zdjęcia",
                photoRequired: "Zdjęcia *",
                operator: 'Operator'
            },
            tooltips: {
                registry: "Numer rejstracyjny drona nadany przez ULC",
                mlu: "Określenie bieżącego położenia, prędkości, wysokości i kierunku lotu bezzałogowego statku powietrznego celem przekazania tych danych do instytucji zapewniającej służbę ruchu lotniczego za pośrednictwem systemu teleinformatycznego lub telefonicznie na żądanie organu ATS",
                elu: "Określenie przez operatora bieżącego położenia bezzałogowego statku powietrznego w przypadku bezpowrotnej utraty możliwości sterowania tym statkiem lub występowania przerw w łączności pomiędzy stacją zdalnego sterowania bezzałogowego statku powietrznego a tym statkiem",
                fpm: "W jaki sposób są monitorowane parametry lotu",
                fps: "W jaki sposób są przechowywane parametry lotu",
                tether: "Maksymalna długośc uwięzi",
                limits: "Znane ograniczenia operacyjne",
                minAge: "Minimalny wiek wymagany prawem",
                impact: "Energia uderzenia dla prędkości granicznej",
                lwa: "Poziom hałasu zgodny z Part 13 & Part 14",
                certification: "Europejskie deklaracje zgodności",
                risk: "Opis ryzyk",
                serialNo: "Numer seryjny drona. W przypadku drona zbudowanego samodzielnie (DIY), prosimy wpisać numer seryjny kontrolera lotów",
                etc_call: "Numer transpondera (SQUAWK)",
                etc_icao: "Oficjalny, przyznany przez nadzór (CAA) kod HEXa transpondera",
                remoteId: "Zdalny numer identyfikacyjny",
                name: "Wpisz nazwę swojego drona aby było można go łatwo zidentyfikować w systemie"
            }
        },
        certTypes : {
            menu: 'Typy Certyfikatów',
            name: 'Typ Certyfikatu |||| Typy Certyfikatów',

            fields : {
                name: 'Typ',
                country: 'Kraj',
                expire: 'Ważny do',
                validity: 'Ważność',
                cert_code: "Kod",
                fields: 'Pola',
                etc_certId: 'ID certyfikatu'
            }
        },

        certs : {
            menu: 'Certyfikaty',
            name: 'Certyfikat |||| Certyfikaty',
            create: 'Nowy Certyfikat',
            typesSelectionLabel: 'Wybierz typ certyfikatu',
            fields: {
                uid: 'Unikalny identyfikator',
                created: 'Dodany',
                modified: 'Zmodyfikowany',
                operator: 'Operator'
            },
            attnInfo: "Załącz skan posiadanego certyfikatu"
        },
        insurances : {
            menu: 'Ubezpieczenia',
            name: 'Ubezpieczenie |||| Ubezpieczenia',
        },
        operators : {
            menu: 'Operatorzy',
            name: 'Operator |||| Operatorzy',
            search: 'Wyszukaj operatora',

            fields: {
                created: 'Dodany',
                email: 'Email',
                username: 'Nazwa Użytkownika',
                emailVerified: 'Weryfkacja adresu email',
                phone: 'Telefon',
                phoneVerified: 'Weryfikacja telefonu',
                firstName : 'Imię',
                lastName : 'Nazwisko',
                nickName: 'Callsign',
                ban: 'Termin blokady konta'
            }
        },
        govs : {
            menu: 'Govs (PL)',
            create: 'Nowy Użytkownik GOV',
            name: 'Gov |||| Govs',
            search: 'Wyszukaj użytkownika typu GOV',
            fields: {

                created: 'Dodany',
                email: 'Email',
                username: 'Nazwa Użytkownika',
                emailVerified: 'Weryfkacja adresu email',
                firstName : 'Imię',
                lastName : 'Nazwisko',
                phone: 'Telefon',
                password: 'Hasło',
                etc: 'Parametry Zaawansowane', //hmm
            },
            permissions: 'Podgląd uprawienień'
        },
        units : {
            menu: 'Units (PL)',
            name: 'Unit |||| Unit\'y',
            create: 'Nowy Unit',
            fields: {
                name: 'Nazwa',
                fullname: 'Pełna nazwa',
                type: 'Typ',
                contact: 'Kontakt',
                created: 'Dodany'
            },
            data: 'Dane Unit\'a',
        },
        unitsAll: {
            fields: {
                name: 'Nazwa',
                fullname: 'Pełna nazwa',
                type: 'Typ',
                contact: 'Kontakt',
                created: 'Dodany'
            }
        },
        unitGovs : {
            menu: 'UnitGovs (PL)',
            name: 'Rola Unit-Govs |||| Role Unit-Govs',
            create: 'Nowa Rola Użytkownika GOV',
            fields: {
                role: 'Rola',
                created: 'Dodany'
            }
        },
        orgs : {
            menu: 'Orgs (PL)',
            fields: {
                created: 'Dodany'
            }
        },
        orgOperators : {
            menu: 'OrgOperators (PL)',
            fields: {
                created: 'Dodany'
            }
        },
        [RESOURCES.EVENT_GOVS]: {
            menu: 'Zdarzenia GOV',
            name: 'Zdarzenie GOV |||| Zdarzenia GOV',
            live: 'Widok strumienia',
            liveWindow: 'Strumień zdarzeń serwera',

            fields: {
                created: 'Utworzono',
                action: 'Akcja',
                subject: 'Podmiot',
                diff: 'Zmiany',
                govid: 'Gov'
            }
        },
        [RESOURCES.EVENT_OPERATORS]: {
            menu: 'Zdarzenia OP',
            name: 'Zdarzenie OPERATOR |||| Zdarzenia OPERATOR',
            live: 'Widok strumienia',
            liveWindow: 'Strumień zdarzeń serwera',

            fields: {
                created: 'Utworzono',
                action: 'Akcja',
                subject: 'Podmiot',
                diff: 'Zmiany',
                operatorid: 'Operator'
            }
        },
        missions : {
            menu: 'Misje',
            name: 'Misja |||| Misje',
            create: 'Nowa Misja',
            //createHelp: 'Aby dodać geometrię, najpierw utwórz misję (flow to fix)',
            duration: "Czas trwania misji:",
            startTimeNormalizeWarning: "Czas trwania misji nie może być krótszy niż 5 minut! Aby zachować ten warunek, " +
                "zmieniono czas startu na %{time}.",
            startTimeMinNormalizeWarning: "Czas rozpoczęcia misji nie może być w przeszłości! Aby zachować ten warunek, " +
                "zmieniono czas startu na %{time}.",
            stopTimeNormalizeWarning: "Czas trwania misji nie może być krótszy niż 5 minut! Aby zachować ten warunek, " +
                "zmieniono czas końca na %{time}.",
            recommendedStartInfoVLOS: 'Misję typu VLOS należy zgłosić do akceptacji minimum %{vlosTime} dzień roboczy przed startem',
            ...(ACM_INFOBOXES_ENABLED && {recommendedStartInfoVLOSwithPriv: 'Dla misji automatycznych (ACM) nie ma ograniczeń dotyczących terminów składania misji. Misje VLOS nieakceptowane automatycznie zostanie rozpatrzona w ciągu 24 godzin od ich z głoszenia. Misje BVLOS nie są akceptowane automatycznie.'}),
            recommendedStartInfoBVLOS: 'Misję typu BVLOS należy zgłosić do akceptacji minimum %{bvlosTime} dni przed startem',
            ...(ACM_INFOBOXES_ENABLED && {recommendedStartInfoBVLOSwithPriv: 'Dla misji automatycznych (ACM) nie ma ograniczeń dotyczących terminów składania misji. Misje VLOS nieakceptowane automatycznie zostanie rozpatrzona w ciągu 24 godzin od ich z głoszenia. Misje BVLOS nie są akceptowane automatycznie.'}),
            loading: "Ładowanie misji",
            attnInfo: "Należy załączyć ewentualne dodatkowe zgody uzyskane od podmiotów zewnętrznych np. zgoda zarządzającego portem lotniczym, zgoda zarządzającego strefą DRA-R, zezwolenie Prezesa ULC na wykonanie operacji w kategorii szczególnej itd.",
            ...(ACM_INFOBOXES_ENABLED && {attnInfoWithPriv: 'Należy załączyć ewentualne dodatkowe zgody uzyskane od podmiotów zewnętrznych np. zgoda zarządzającego portem lotniczym, zgoda zarządzającego strefą DRA-R, zezwolenie Prezesa ULC na wykonanie operacji w kategorii szczególnej itd.'}),
            timeDialogTitle: "Terminy urzędowe",
            timeDialogLabel: ASM_NEW_LABELS ?
                "Nie zachowano terminu zgłoszenia misji do Działu Zarządzania Operacjami BSP PAŻP. Dla misji typu VLOS wymagane są %{vlosTime} dni robocze, dla misji typu BVLOS %{bvlosTime} dni. " +
                "Uwaga! Wniosek może pozostać nierozpatrzony."
                :
                "Nie zachowano terminu zgłoszenia misji do ASM. Dla misji typu VLOS wymagane są %{vlosTime} dni robocze, dla misji typu BVLOS %{bvlosTime} dni. " +
                "Uwaga! Wniosek może pozostać nierozpatrzony.",
            noConflictsDialogTitle: "Brak konfliktów",
            noConflictsDialogLabel: "Misja VLOS nie zawiera konfliktów. Nie ma potrzeby zgłaszania jej do akceptacji. W dniu lotu sprawdź ograniczenia przestrzeni powietrznej, a także dla wspólnego bezpieczeństwa zrób check-in w aplikacji mobilnej.",
            ...(MISSION_112_ENABLED && {abuse112: "Uwaga! Misja jest oznaczona jako uprzywilejowana 112, natomiast operator nie ma uprawnień do wykonywania takich misji. " +
                "Najprawdopodobniej uprawnienie zostało cofnięte po wysłaniu misji do akceptacji."}),
            steps: {
                missionNameAndType: 'Nazwa i typ misji',
                duration: 'Czas trwania',
                additionalInfo: 'Informacje dodatkowe',
                target: 'Kategorie lotu',
                aircrafts: 'Statki powietrzne',
                observers: 'Obserwatorzy i załączniki',
                graphicalMissionEditor: 'Edytor graficzny misji',
                ...(MISSION_112_ENABLED && {mission112: 'Misja 112'}),
            },
            fields: {
                uid: 'Unikalny identyfikator',
                approval : 'Zgoda nr',
                additionalDocs: MISSION_DROPDOWN_SUPPORTED_TYPES_ENABLED ?
                    'Dodatkowe dokumenty do misji (*.jpg, *.pdf)'
                    :
                    'Dodatkowe dokumenty do misji',
                created: 'Dodano',
                modified: 'Zmodyfikowano',
                start: 'Start (czas lokalny)',
                stop: 'Koniec (czas lokalny)',
                status: 'Status',
                name: 'Nazwa',
                type: 'Typ',
                mcmt: "Minimalny czas nieprzerwanego trwania misji wyrażony w minutach (MCMT)",
                etc_pilotNr: "Numer pilota",
                etc_operatorNr: "Numer operatora",
                etc_fallbackDates: 'Dodatkowe terminy',
                etc_shortDescription: 'Krótki opis',
                etc_activityType: 'Kategorie lotu',
                etc_puropse_sport: 'Otwarta',
                etc_purpose_other: 'Szczególna lub certyfikowana',
                etc_puropse_training: 'Szczególna - NSTS lub STS',
                etc_purpose_spec: 'Szczególna - zezwolenie ULC',
                etc_puropse_auto: 'Szczególna - LUC',
                etc_purpose_operational: 'Certyfikowana',
                etc_observers: 'Obserwatorzy',
                etc_additionalObservers: 'Dodatkowi obserwatorzy',
                etc_observerName: 'Imię i nazwisko obserwatora',
                etc_observerPhone: 'Telefon GSM obserwatora',
                etc_drones: 'Rodzaj i Ilość statków powietrznych',
                etc_smbt: 'Dodatkowy czas lotu na wypadek konieczności oczekiwania w powietrzu wyrażony w minutach (SMBT)',
                etc_noConflictsDialogYesUnderstood: 'Dotyczy misji dla których nie wykryto konfliktów z innymi przestrzeniami na etapie planowania - potwierdzono zrozumienie braku konfliktów w zadeklarowanym obszarze i czasie.',
                etc_noConflictsDialogNotUnderstood: 'Dotyczy misji dla których nie wykryto konfliktów z innymi przestrzeniami na etapie planowania - nie potwierdzono zrozumienia braku konfliktów w zadeklarowanym obszarze i czasie!',
                ...(MISSION_112_ENABLED && {etc_is112: 'Misja uprzywilejowana 112'}),
                etc_isKSID: 'Misja KSID',
            },
            labels: {
                contactData: 'Dane kontaktowe',
            },
            editor: 'Edytor Misji',
            status: {
                accepted: 'Misja zaakceptowana |||| Misje zaakceptowane',
                rejected: 'Misja odrzucona |||| Misje odrzucone',
                pending: 'Misja oczekująca |||| Misje oczekujące',
                unknown: 'Misja z nienznanym statusem |||| Misje z nieznanym statusem',
                created: 'Misja utworzona |||| Misje utworzone'
            },
            notifications: {
                statusChangedMsg: 'Status misji został zmieniony',
                legsTooLargeErr: 'Co najmniej jeden sektor misji ma za duży obszar',
                legsOutsideBorder: 'Co najmniej jeden sektor misji jest poza granicami Polski',

                legsNotValidErr: 'Co najmniej jeden sektor misji ma niepoprawną geometrię',
                saveFailedErr: 'Błąd zapisu misji',
                awaitingForResolveMsg: 'Misja zapisana, liczenie konfliktów...',
                cloneFailedErr: 'Nie udało się sklonować misji',
                cloning: 'Trwa klonowanie misji',
                holidaysCheckError: "Nie można sprawdzić kalendarza świąt. Kalkulacje względem oficjalnych terminów rozpatrzenia niemożliwe."
            },
            tooltips: {
                acceptSystemDisabled: "Wprowadź imię, nazwisko, callsign i numer telefonu w ustawieniach aby móc wysłać misję do akceptacji",
                acceptEditorDisabled: "Zapisz zmiany lub narysuj co najmniej jeden obszar przebiegu misji wraz określeniem górnej i dolnej granicy wysokości, a następnie zapisz aby móc wysłać misję do akceptacji",
                phoneInputDisabled: "Aby wprowadzić lub zmienić numer telefonu przejdź do ustawień profilu",
                noConflictsDisabled: "Misja nie zawiera konfliktów, nie ma potrzeby akceptacji",
                name: "Postaraj się aby aby nazwa najlepiej opisywała charakter operacji",
                smbt: "Maksymalny czas oczekiwania w powietrzu, ponad planowany podany w minutach na wypadek konieczności oczekiwania na zgodę na przelot przez daną strefę",
                mcmt: "Minimalny akceptowalny czas ciągłej misji aby uznać misję za udaną",
                shortDescription: "Jeśli w Twojej misji, jest coś nietypowego lub wyjątkowego, opisz to tutaj",
                activityType: "Kategoria lotu zgodnaz klasyfikacją określoną w oficjalnych regulacjach",
                aircrafts: "Proszę wybrać statki powietrzne którymi będzie wykonywana misja",
                observers: "Jeśli lot wymaga dodatkowych obserwatorów (np. dla lotów EVLOS), proszę ich wpisać wraz z aktualnym numerem telefonu. " +
                    "Proszę się upewnić, że został podany prawidłowy numer telefonu",
                is112: "Masz uprawnienia do nadawania misjom statusu 112. W przypadku włączenia statusu, " +
                    "służby ATC będą traktowały tę misję priorytetowo.",
                pilotNr: "Wpisz numer pilota w formacie xxx-xx-xxxxxxxxxxxx",
                operatorNr:"Wpisz numer operatora w formacie xxxxxxxxxxxxxxxx (16 znaków)"
            }
        },
        zones : {
            menu: 'Strefy',
            name: 'Strefa |||| Strefy',
            create: 'Nowa Strefa',
            createHelp: 'Aby dodać geometrię, najpierw utwórz misję (flow to fix)',
            fields: {
                name: 'Nazwa',
                type: 'Typ',
                created: 'Dodano',
                unitName: 'Unit (PL)',
                country: 'Kraj',
                othername: 'Inna nazwa',
                start: 'Start',
                stop: 'Koniec',
                activity: 'Aktywność',
                source: 'Źródło',
                contact: 'Kontakt',
                restriction: 'Ograniczenia',
                min: "Dolna granica wysokości (ft AMSL)",
                max: "Górna granica wysokości (ft AMSL)",
                description: {
                    pl: "Opis (PL)",
                    en: "Opis (EN)"
                },
            },
            zoneLegsCnt: 'Konflikty',
            editor: 'Edytor Stref',
            notifications: {
                saveFailedErr: 'Błąd zapisu strefy',
                oneAreaRequiredErr: 'Strefa musi mieć dokładnie jeden obszar',
                wrongMinMaxErr: 'Złe wartości min/max',
                deleteFailedErr: 'Błąd usuwania strefy. %{status}'

                //awaitingForResolveMsg: 'Strefa zapisana, liczenie konfliktów...',
            },
            useDescriptionTemplate: "Użyj szablonu do opisu strefy",
            tooltips: {
                noPermissionsToArchive: 'Nie masz uprawnień do usunięcia strefy'
            }

        },
        zoneTypes: {
            menu: "Typy Stref",
            name: "Typ Strefy |||| Typy Stref",
            fields: {
                name: 'Nazwa',
                description: {
                    pl: "Opis (PL)",
                    en: "Opis (EN)"
                }
            }
        },
        pois : {
            //POI naming to fix?
            menu: 'POIs (PL)',
            name: 'POI (PL) |||| POIs (PL)',
            create: 'Nowe POI',
            //createHelp: 'Aby dodać geometrię, najpierw utwórz misję (flow to fix)',
            fields: {
                name: 'Nazwa',
                type: 'Typ',
                created: 'Dodano',
                unitName: 'Unit (PL)',
                country: 'Kraj',
                othername: 'Othername (PL)',
                start: 'Start',
                stop: 'Koniec',
                activity: 'Aktywność',
                source: 'Źródło',
                contact: 'Kontakt',
                description: 'Opis',
                restriction: 'Ograniczenia'
            },
            editor: 'Edytor POI',
            notifications: {
                //saveFailedErr: 'Błąd zapisu strefy',
                //oneAreaRequiredErr: 'Strefa musi mieć dokładnie jeden obszar',
                //wrongMinMaxErr: 'Złe wartości min/max',

                //awaitingForResolveMsg: 'Strefa zapisana, liczenie konfliktów...',
            },
        },
        faas : {
            menu: 'Notamy FAA',
            name: 'Notam |||| Notamy',

            fields: {
                created: 'Dodano',
            }
        },
        zoneLegs : {
            menu: 'ZoneLegs(PL)',
            fields: {
                created: 'Dodano',
                status: 'Status',
            },
            zoneName : 'Strefa',
            unresolvedConflicts: 'Nierozwiązane konflikty',
            allConflicts: 'Wszystkie konflikty',
            conflicts: 'Konflikty'

        },
        legs : {
            menu: 'Legs(PL)',
            fields: {
                created: 'Dodano',
            },

        },
        notams: {
            menu: 'Notamy',
            name: 'Notam |||| Notamy',
            create: 'Nowy Notam',
            publishing: 'Trwa publikacja NOTAM\'u',
            validation: {
                lower: 'Granica dolna nie może być większa lub równa granicy górnej',
                upper: 'Granica górna nie może być mniejsza lub równa granicy dolnej',
            },
            activitiesReminder: 'Nie zapomnij dodać aktywności NOTAMU!',
            fields: {
                created: 'Dodano',
                seriesLine: 'Seria',
                series: 'Seria, numer w sekwencji, rok wydania',
                type: 'Typ',
                seriesref: 'Seria, numer w sekwencji, rok wydania NOTAM\'u referencyjnego',
                qLine: 'Linia Q',
                q: {
                    fir: 'FIR',
                    code: 'Kod',
                    traffic: 'Ruch',
                    purpose: 'Cel',
                    scope: 'Zasięg',
                    lower: 'Granica dolna',
                    upper: 'Granica górna',
                    coords: 'Koordynaty, promień'
                },
                aLine: 'Linia A',
                a: 'Wskaźnik lokalizacji ICAO, którego dotyczy NOTAM',
                bLine: 'Linia B',
                b: 'Ważność od',
                cLine: 'Linia C',
                c: 'Ważność do',
                dLine: 'Linia D',
                d: 'Harmonogram aktywności',
                actDefName: 'Definicje aktywności',
                actDef: {
                    periodMode: 'Tryb czasu',
                    repeatMode: 'Tryb powtórzeń',
                    begin: 'Start',
                    end: 'Stop',
                    months: 'Miesiące',
                    days: 'Dni',
                    weekdays: 'Dni tygodnia',
                    slotMode: 'Tryb okna czasowego',
                    hourSlots: 'Okna czasowe',
                },
                eLine: 'Linia E',
                e: 'Tekst NOTAMU',
                fLine: 'Linia F',
                f: 'Granica dolna',
                gLine: 'Linia G',
                g: 'Granica górna',
                preview: 'Podgląd NOTAM\'u',
                activationsCalc: 'Kalkulacja aktywności',
                activations: 'Aktywności',
                zoneDataLine: 'Dane strefy PansaUTM',
                zoneReferenceType: 'Typ referencji do strefy',
                zonename: 'Nazwa strefy',
                zonetype: 'Typ strefy',
                activityRepeatModes: {
                    days: 'Dni',
                    weekdays: 'Dni tygodnia',
                    months: 'Miesiące',
                    nonStop: 'Non-stop',
                },
                activityTimeModes: {
                    single: 'Pojedyńczy przedział czasu',
                    repeat: 'Powtarzalny przedział czasu'
                },
                activitySlotModes: {
                    h24: 'H24',
                    slots: 'Okna'
                },
                zoneReference: {
                    new: 'Nowa strefa',
                    exist: 'Istniejąca strefa'
                },
                hourSlots: "Okna godzinowe",
                description: {
                    pl: "Opis (PL)",
                    en: "Opis (EN)"
                },
                deactivated: 'DEZAKTYWOWANY',
            }
        },
        [RESOURCES.ACM_RULES]: {
            menu: 'ACM',
            name: 'Reguła ACM |||| Reguły ACM',
            createException: 'Utwórz wyjątek',
            create: 'Utwórz regułę ACM',
            defaultRule: 'Reguła domyślna',
            exceptionRule: 'Wyjątek od reguły domyślnej |||| Wyjątki od reguły domyślnej',
            acmAsmToggle: 'Używaj ACM do akceptacji misji',
            acmTwrToggle: 'Używaj ACM do akceptacji checkinów na TWR',
            acmRpaDialogTitle: 'Ustawienia ACM dla RPA',
            acmRpaDialogLabel: 'Obecna konfiguracja dla %{rpa} w %{ctr}',
            acmTermsCreatorDialogTitle: 'Ustawienia warunków automatycznych ACM',
            acmTermsCreatorDialogLabel: 'Konfiguracja dla %{ctr}',
            acmTermsCreatorDialogSubheader: 'INSTRUKCJA: Wprowadź definicje warunków dla zgód automatycznych ACM. Wymagane są ' +
                'warunki domyślne. W przypadku nie wprowadzenia warunków poszczególnych typów (kolorów) RPA, ' +
                'ACM będzie używał warunków domyślnych. Wprowadzone warunki zostaną na podstawie typu przypisane ' +
                'wszystkim RPA w %{ctr}. Edycja warunków ACM jednostkowych RPA będzie możliwa na dalszym etapie.',
            acmRulesTablePreview: 'Widok tabeli reguł ACM dla %{ctr}',
            editingLocked: 'Edycja zablokowana. Najpierw zdefiniuj tabelę reguł domyślnych dla %{ctr}.',
            infoOnlyActive: 'Wyświetlane są tylko aktywne reguły',

            fields: {
                created: 'Utworzono',
                designator: 'Lokalizacja',
                start: 'Okno godzinowe (UTC)',
                blue: 'RPA niebieskie',
                green: 'RPA zielone',
                yellow: 'RPA żółte',
                red: 'RPA czerwone',
                parentid: 'ID reguły domyślnej',
                etc: {
                    terms: {
                        def: 'Domyślne',
                        red: 'W czerwonym RPA',
                        yellow: 'W żółtym RPA',
                        green: "W zielonym RPA",
                        blue: "W niebieskim RPA",
                        header: "Definicje warunków zgody automatycznej ACM",
                        description: {
                            pl: "Definicja (PL)",
                            en: "Definicja (EN)"
                        }
                    }
                }
            },
            notifications: {
                feedingAcm: 'Uzupełnianie wartości domyślnych',
                errorOnLoading: 'Nie można załadować reguł ACM',
                savingAcmRpa: 'Zapisywanie zmian w ACM',
                errorOnSaving: "Nie można zapisać reguł ACM. %{status}",
            },
            validation: {
                onlyOneTerms: 'Dla danego typu RPA warunków ACM wymagane definicje dla każdego języka lub wszystkie puste',
                defaultsEmpty: 'Warunki domyślne nie mogą być puste',
            }
        },
    },
    myroot: {
        rodo: 'Klauzula RODO',
        action: {
            clear: 'Wyczyść',
            clearForm: 'Od nowa',
            execute: 'Wykonaj',
            finish: 'Zakończ',
            reset: 'Reset',
            send: 'Wyślij',
            sendForAccept: 'Wyślij do akceptacji',
            settings: 'Ustawienia',
            notShowAgain: 'Nie pokazuj więcej',
            sortBy: "Sortuj po",
            order: "Kolejność",
            sortAscending: "Rosnąco",
            sortDescending: "Malejąco",
            viewOnly: "Tylko podgląd",
            understood: "Zrozumiałem",
        },
        admin: {
            actionsLabel: 'Akcje',
            actions: {
                resendEmail: 'Wyślij ponownie email weryfikacyjny',
                banUser: 'Zablokuj konto użytkownika',
                unbanUser: 'Odblokuj konto użytkownika',
                setPrivilege112UAVOandSUAVO: 'Nadaj uprawnienia UAVO 112 i Super Pilot',
                setPrivilegeSUAVO: 'Nadaj uprawnienie UAVO Super Pilot',
                unsetPrivilege112UAVO: 'Cofnij uprawnienie UAVO 112',
                unsetPrivilegeSUAVO: 'Cofnij uprawnienie UAVO Super Pilot'
            },
            logs: 'Logi',
            messages: {
                resendEmailSuccess: 'Wysłano ponownie email weryfikacyjny',
                resendEmailError: 'Nie udało się wysłać ponownie email\'a weryfikacyjnego. Status: %{status}',
                banUserSuccess: 'Zablokowano konto użytkownika na 100 lat',
                banUserError: 'Nie udało się zablokować konta użytkownika. Status: %{status}',
                unbanUserSuccess: 'Odblokowano konto użytkownika',
                unbanUserError: 'Nie udało się odblokować konta użytkownika. Status: %{status}',
                priviSetSuccess: 'Zaktualizowano uprawnienie',
                priviSetError: 'Nie udało się zaktualizować uprawnienia. Status: %{status}'
            },
            tools: 'Narzędzia administracyjne'
        },
        appBar: {
            language: 'Język',
            help: 'Pomoc'
        },
        message: {
            saving: "Trwa zapisywanie",
            wait: "Proszę czekać...",
            logoutTimeout: 'Za chwilę nastąpi wylogowanie...',
            actionExecute: 'Wykonywanie akcji',
        },
        navigation: {
            back: 'Wstecz',
            more: 'Więcej',
            skip: 'Pomiń',
        },
        httpCode : {
            401: '401: Bład autoryzacji'
        },
        dialog : {
            ok : 'OK',
            cancel : 'Anuluj',
            testMsg : 'Test Message (PL)',
            testTitle : 'Test Title (PL)',
        },

        auth: {
            accountSettings: 'Ustawienia konta',
            accountData: 'Dane konta',
            username: 'Nazwa konta',
            email: 'Email',
            password: 'Hasło',
            newPassword: 'Nowe Hasło',
            emailOrUsername: 'Email lub nazwa konta',
            signIn: 'Zaloguj',
            signingIn: 'Logowanie',
            signUp: 'Rejestruj',
            jumpToAccountSettings: "Przejdź do ustawień konta",

            login: 'Zaloguj',
            logout: 'Wyloguj',

            logIn: 'Zaloguj',
            logOut: 'Wyloguj',

            register: 'Rejestracja',
            resetPassRq: 'Reset hasła',
            resetPass: 'Reset hasła',
            reject: 'Odrzuć',
            retype : 'Powtórz hasło',
            confirm : 'Potwierdź',
            verify : 'Zweryfikuj',
            createNickName:'Utwórz callsign',

            matchError : 'Hasło nie pasuje',
            authError: 'Błąd autentykacji, spróbuj ponownie',
            registerRqError: 'Błąd danych rejestracji',
            registrationMailSentMsg : 'Mail rejestracyjny został wysłany na adres %{email}, sprawdź pocztę żeby kontynować.',
            registrationVerificationMsg : 'Opcjonalnie możesz wprowadzić otrzymanego linka, żeby zweryfikować rejestrację',
            verificationLink : 'link weryfikacyjny',
            passwordResetRequestMsg : 'Podaj mail związany z kontem, żeby zresetować hasło',
            passwordResetRequestFailMsg : 'Błąd resetu hasła',
            passwordResetDataMsg : 'Podaj nowe hasło',
            passwordFinalResetPassSuccess : 'Hasło zostało zmienione, przejdż do okna logowania.',
            passwordResetMailSentMsg : 'Mail z resetem hasła został wysłany na adres %{email}, sprawdź pocztę żeby kontynować.',
            passwordVerificationMsg : 'Wprowadź otrzymanego linka, żeby zweryfikować tworzenie nowego passwordu',

            passwordResetSuccessMsg : 'Hasło zostało zresetowane, zaloguj się żeby kontynuować.',
            onEmailVerifiedMsg : 'Mail został zweryfikowany, zaloguj się żeby kontynuować.',
            accountReadMsg : 'Dane konta pobrane',
            accountReadError : 'Błąd odczytu konta',
            accountUpdateFailMsg : 'Błąd aktualizacji danych konta',
            accountUpdatedMsg : 'Dane konta zostały zaktualizowane',
            accountUpdatedNothingMsg : 'Dane konta nie zostały zaaktualizowane',
            accountUpdatedNicknameMsg: 'Imię, nazwisko i callsign zostały zaktualizowane',
            privacyPolicyInfo: 'Informacja w sprawie przetwarzania danych osobowych',
            phoneChangeRequestSuccessMsg: 'Wysłano żądnie zmiany nr telefonu. Czekaj na sms z kodem weryfikacyjnym',
            phoneChangeRequestFailMsg: 'Błąd żądania zmiany nr telefonu!',
            phoneVerifySuccessMigrationMsg: "Znaleziono użytkownika mobilnego z tym samym numerem telefonu. Twoje konta zostały połączone. Za chwilę nastąpi wylogowanie - zaloguj się ponownie we wszystkich aplikacjach.",
            phoneVerifySuccessMsg: "Numer telefonu został zweryfikowany pomyślnie!",
            phoneVerifyFailMsg: "Niepoprawny kod weryfikacyjny. Spróbuj ponownie!",
            editionLocked: "Edycja danych jest zablokowana. Co najmniej jeden wniosek czeka na weryfikację.",
            editionLockedOffline: "Edycja danych jest zablokowana. Prawdopodobnie nie masz połączenia z internetem." +
                "Sprawdź połączenie i otwórz ponownie okno ustawień konta.",
            sendLockedPhoneNotVerified: "Numer telefonu nie jest zweryfikowany. Wysyłanie misji lub wniosków do akceptacji będzie niemożliwe.",
            sendLockedNoFullName: "Nie podano imienia i nazwiska. Wysyłanie misji lub wniosków do akceptacji będzie niemożliwe.",
            sendLockedNoCompany: "Nie podano danych firmy. Wysyłanie wniosków do akceptacji jako firma będzie niemożliwe.",
            sendLockedNoNickName: "Nie wybrano unikalnego callsignu. Wysyłanie misji lub wniosków do akceptacji będzie niemożliwe.",
            nickNameDialogTitle: "Wybierz unikalny callsign",
            nickNameDialogHeader: "Callsign jest znakiem rozpoznawczym dla twoich misji i bezzałogowych " +
                "statków powietrznych. Na jego podstawie Służby Kontroli Ruchu Lotniczego będą Cię identyfikować. " +
                "Zmiana callsignu jest możliwa tylko w przypadku gdy zmieni się Twoje imię lub nazwisko.",
            termsOfUse: "Warunki użytkowania",
            disclaimer: "Zastrzeżenia prawne"
        },
        configuration: {
            about: "Informacje",
            aboutSystem: 'Informacje o systemie',
            appSettings: "Ustawienia systemu",
            app: "System",
            commercialServices: "Usługi komercyjne",
            commercialServicesSettings: "Ustawienia usług komercyjnych",
            notificationsSettings: "Ustawienia powiadomień",
            notifications: "Powiadomienia",
            notificationsInfoBoxSounds: "Dźwięki komponentu INFO BOX",
            notificationsResetInfoBoxHistory: "Wyczyść historię widzianych powiadomień w INFO BOX",
            notificationsResetInfoBoxHistoryDone: "Wyczyszczono pomyślnie historię widzianych powiadomień INFO BOX",
            notificationsAlertBoxSounds: "Dźwięki komponentu ALERT BOX",
            view: "Widok",
            viewSettings: "Ustawienia widoku",
            viewDashResetDone: 'Przywrócono układ domyślny na panelu głównym',
            viewDashboardLayoutReset: 'Przywróć układ domyślny na panelu głównym',

        },
        dashboard : {
            title : 'witaj w daszbordzie',
            ctrZonesHeader : 'Strefy CTR',
            fetchError : 'Błąd pobierania dashboardu',
            accountForm : {
                username : 'Nazwa Użytkownika',
                usernameDscr : 'Unikalny identyfikator konta',
                email : "E-mail",
                emailDscr : "Konto pocztowe użytkownika",
                phone : "Telefon",
                phoneDscr : "Kontaktowy telefon komórkowy",
                phoneVerified: "Telefon zweryfikowany",
                personalData : "Dane Osobowe",
                personalDataDscr : "Dane Osobowe (rules of engagment do dodania)",
                dob : "Data urodzenia",
                firstName : "Imię",
                lastName : "Nazwisko",
                emailVerified : 'Email zweryfikowany',
                smsCode : "Kod SMS",
                smsCodeSentAt: "Kod SMS wysłany:",
                smsCodeDscr : "Wprowadź otrzymany kod SMS na:",
                smsCodeAgain: "Wyślij kod ponownie",
                smsCodeResendLocked: "Aby wysłać sms weryfikacyjny poczekaj 2 minuty, następnie wyloguj się i zaloguj ponownie",
                changeNumber: "Zmień numer telefonu",
                smsVerification: "Weryfikacja sms",
                territoryOfOperation: "Terytorium działania",
                providedServices: "Wykonywane usługi",
                nickName: 'Callsign',
            }
        },
        zoneConflictResolver :{
            issuedBy: 'Wydano przez:',
            unitName: 'Nazwa Jednostki:',

            unitApprovalRejected: 'Odrzucono',
            unitApprovalConfirmed: 'Zaakceptowano',
            rejectDialogTitle: 'Odrzucenie misji',
            rejectDialogDefaultText: 'Powód odrzucenia: ',
            rejectDialogLabel: 'Dodatkowe informacje o odrzuceniu',
            rejectBtn: 'Odrzuć',
            acceptDialogTitle: 'Akceptacja misji',

            acceptDialogLabel: 'Dodatkowe informacje o akceptacji: ',
            acceptDialogOverrideMsg: 'Misja wymaga dodatkowych zgód, zaakceptuj ją tylko jeśli operator potwierdził ich posiadanie',
            acceptDialogPartialMsg: 'Misja wymaga dodatkowych zgód, możesz zaakceptować tylko swoje strefy',

            acceptMineLabel: 'Akceptuj Moje Strefy',
            acceptFullLabel: 'Akceptuj Misje',
            acceptBtn: 'Akceptuj',
        },
        company: {
            data: "Dane firmy",
            name: "Nazwa podmiotu",
            name2: "Firma",
            tax: "Numer NIP podmiotu",
            commercialRodo: "Zgadzam się na przetwarzanie w celach marketingowych, w tym poprzez profilowanie, oraz w celach analitycznych, moich danych osobowych pozostawianych przeze mnie w ramach korzystania z modułu usług komercyjnych, przez Droneradar Sp. z o.o. w celu marketingu produktów i usług podmiotów trzecich oraz i przez podmioty współpracujące z Droneradar Sp. z o.o. oraz Zaufanych partnerów.",
            commercialRodoHeader: "Zgoda marketingowa",
            commercialRodoNotChecked: "Usługa nie będzie aktywna! Proszę zaznaczyć zgodę marketingową...",
            workingHours: {
                name: "Godziny pracy",
                start: "od",
                stop: "do"
            },
            mainDisclaimer: "Wszyscy członkowie personelu bezpośrednio zaangażowani w operacje mają kompetencje konieczne, aby wykonywać swoje zadania, a bezzałogowy system powietrzny będzie obsługiwany wyłącznie przez pilotów bezzałogowych statków powietrznych posiadających odpowiedni poziom kompetencji",
            disclaimers: "Klauzule",
            services: {
                techInspection: "Inspekcje techniczne",
                workProgress: "Dokumentowanie postępów prac",
                patrol: "Loty patrolowe, nadzorcze",
                airMonitoring: "Monitoring stanu jakości powietrza",
                agriculturalInspection: "Inspekcje upraw rolnych",
                forestInspection: "Inspekcje terenów lesnych",
                waterInspection: "Inspekcje stanu koryt rzek i zalewów, stanu zamulenia",
                modelling: "Modelowanie 3D",
                antiqueInspection: "Kontrola zabytków",
                animalsCounting: "Liczenie zwierząt",
                cargoDrop: "Zrzucanie cargo",
                movie: "Filmowanie",
                movieHD: "Filmowanie HD",
                movie4K: "Filmowanie 4K",
                movieNight: "Flimowanie noktowizyjne (night vision, NOD, NVG)",
                streaminghHD: "Live-Streaming half-HD (720p)",
                streamingHD: "Live-Streaming HD (1080p/1080i)",
                streaming4K: "Live-Streaming 4K",
                weddings: "Nagrywanie ślubów",
                energyInspections: "Kontrola linii energetycznych",
                gasInspection: "Kontrola rurociągów gazowniczych",
                oilInspection: "Kontrola rurociągów z ropą",
                heatInspection: "Kontrola sieci ciepłowniczej",
                tracking: "Śledzenie detektywistyczne",
                ortofoto: "Tworzenie ortofotomap",
                ortotermo: "Tworzenie ortotermomap",
                thermovision: "Przeglądy termiczne budynków (termowizja)",
                searchThermovision: "Loty poszukiwawcze z wykorzystaniem termowizji",
                search: "Loty poszukiwawcze",
                searchNightVision: "Loty poszukiwacze z wykorzystaniem noktowizji (night vision, NOD, NVG)",
                measurement: "Loty pomiarowe (objętość, powierzchnia)",
                race: "Wyścigi dronów",
                lightShow: "Pokazy choreografii światła (drone light show)",
                spectometry: "Spektometria",
                agriculturalSprays: 'Opryski rolnicze',
                decontamination: 'Odkażanie',
                other: "Inne",
            }
        },
        address: {
            address : "Adres",
            town : "Miejscowość",
            zipcode : 'Kod pocztowy',
            country : 'Kraj',
            addressData : "Dane adresowe",
            addressDscr : "Dane adresowe",
        },
        validation : {
            required : "Wymagane",
            optional : "Opcjonalne",
            //common validation for custom field types
            nullableJSONObject: "Opcjonalna wartość musi być poprawnym obiektem JSON",
            JSONObject: "Wymagany poprawny obiekt JSON",
            usernameOrEmailFormat: 'Wymagana poprawna nazwa użytkownika (konta) - email niedozwolony',
            whiteSpaceOnEdgeNotAllowed: 'Białe znaki nie dopuszczalne na początku lub końcu (np. spacja)',
            nameFormat: 'Poprawne tylko litery oraz między wyrazami myślnik, kropka, apostrof lub spacja',
            passwordFormat: `Wymagane co najmniej: 8 znaków, jedna litera, jedna cyfra i jeden znak specjalny ze zbioru: !@#$%^&*\\({}-+=,.<>;:\'"/`

        },
        info: {
            vlosSelection: {
                title: "<b>VLOS</b>",
                content: INFOBOX_NEW_MISSION_INFOS_ENABLED ?
                    "Loty VLOS realizowane są w oparciu o Rozporządzenie Wykonawcze Komisji (UE) 2019/947 z dnia 24 maja 2019 r. w sprawie przepisów i procedur dotyczących eksploatacji bezzałogowych statków powietrznych. Po więcej informacji zapraszamy na <a href='http://www.pansa.pl/drony' target=”_blank”>www.pansa.pl/drony</a> "
                    :
                    "Aktualnie obsługiwane procesy:\n" +
                    "\n" +
                    "- pomoc w uzyskaniu zgody na lot w zasięgu widoczności wzrokowej (VLOS) w przestrzeniach kontrolowanych (CTR)\n" +
                    "\n" +
                    "- lot EVLOS: lot VLOS z dodatkowymi obserwatorami w przestrzeni kontrolowanej"
            },
            ...(ACM_INFOBOXES_ENABLED && {vlosSelectionWithPriv: {
                title: "<b>Loty VLOS z ACM</b>",
                content:  "Jesteś użytkownikiem typu %{privileges}. Otrzymasz automatyczną akceptację misji (ACM) w strefie DRA-R CTR, w miarę dostępności wolumenu przestrzeni RPA. Automatyczne zgody przedtaktyczne, mogą być wydawane wyłącznie na misje złożone w granicach poziomych i pionowych stref RPA (jeden rejon lotu oraz maksymalna wysokość lotu w stopach AMSL nieprzekraczająca górnej granicy rejonu RPA). Reguły przyznawania automatycznych zgód i pojemności rejonów RPA są dostępne w zakładce Pomoc. W przypadku misji nie podlegających pod automatyczne zgody, oczekuj na akceptację misji przez Supervisora DTM."
            }}),
            bvlosSelection: {
                title: "<b>BVLOS</b>",
                content: INFOBOX_NEW_MISSION_INFOS_ENABLED ?
                "Loty BVLOS realizowane są w oparciu o Rozporządzenie Wykonawcze Komisji (UE) 2019/947 z dnia 24 maja 2019 r. w sprawie przepisów i procedur dotyczących eksploatacji bezzałogowych statków powietrznych. Po więcej informacji zapraszamy na <a href='http://www.pansa.pl/drony' target=”_blank”>www.pansa.pl/drony</a> "
                    :
                    "Aktualnie obsługiwane procesy\n" +
                    "\n" +
                    "- pomoc w uzyskaniu zgody na lot poza zasięgiem widoczności wzrokowej (BVLOS)"
            },
            ...(ACM_INFOBOXES_ENABLED && {bvlosSelectionWithPriv: {
                    title: "<b>Loty BVLOS z ACM</b>",
                    content:  "Jesteś użytkownikiem typu %{privileges}. Wnioski BVLOS nie są obsługiwane w systemie " +
                        "automatycznych akceptacji ACM. Po przesłaniu wniosku oczekuj na akceptację misji przez " +
                        "Supervisora DTM."
                }}),
            dronePhotoSerial: {
                title: "<b>Potwierdzenie autentyczności</b>",
                content: "Prosimy o załączenie zdjęcia z numerem seryjnym drona. " +
                    "Jeśli dron jest urządzeniem nieseryjnym (DIY) prosimy o zdjęcie numeru seryjnego kontrolera lotu (może to być zdjęcie z ekranu programu obsługującego kontroler)."
            },
            missionEditor: {
                title: "<b>Narysuj swoją misję</b>",
                content: "Narysuj rejon lotu. Pamiętaj, że Twoja misja może składać się z kilku rejonów lotu o różnych kształtach: okręgów, wielokątów itp. Dla każdego rejonu lotu określ minimalną i maksymalną wysokość lotu. Dostosuj wyznaczony rejon lotu do odpowiedniej rodzaju lotu: VLOS lub BVLOS. Na górze mapy znajdują się przełączniki, którymi można zmieniać parametry wyświetlanej mapy oraz warstwy."
            },
            targetBvlos: {
                title: "<b>BVLOS</b>",
                content: "Loty BVLOS realizowane są w oparciu o Rozporządzenie Wykonawcze Komisji (UE) 2019/947 z dnia 24 maja 2019 r. w sprawie przepisów i procedur dotyczących eksploatacji bezzałogowych statków powietrznych. Po więcej informacji zapraszamy na <a href='http://www.pansa.pl/drony' target=”_blank”>www.pansa.pl/drony</a>"
            },
            targetVlos: {
                title: "<b>VLOS</b>",
                content: "Loty VLOS realizowane są w oparciu o Rozporządzenie Wykonawcze Komisji (UE) 2019/947 z dnia 24 maja 2019 r. w sprawie przepisów i procedur dotyczących eksploatacji bezzałogowych statków powietrznych. Po więcej informacji zapraszamy na <a href='http://www.pansa.pl/drony' target=”_blank”>www.pansa.pl/drony</a> "
            },
            alert: {
                certChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Zmieniono status weryfikacji (certyfikaty)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Certyfikat: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "Nowy status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Zmieniono status wniosku certyfikatu. Odśwież listę certyfikatów.</b>",
                droneChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Zmieniono status weryfikacji (drony)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Dron: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "Nowy status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Zmieniono status wniosku drona. Odśwież listę dronów.</b>",
                missionChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Zmieniono status akceptacji (misje)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Misja: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "Nowy status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Zmieniono status misji. Odśwież listę misji.</b>",
            }
        }
    },
    error: {
        //backend translatable errors fixme after backend review...
        EmailNonUnique : 'Email już zarejestrowany',
        ValidationError : 'Błąd walidacji',
        FailedAuthCheck : 'FailedAuthCheck',
        INVALID_TOKEN : 'Błedny token',
        LOGIN_FAILED: 'Bład loginu',
        EMAIL_NOT_FOUND : 'Email nie zarejestrowany',
        LOGIN_FAILED_EMAIL_NOT_VERIFIED: 'Email nie został jeszcze zweryfikowany',
        NO_RESPONSE: 'Brak odpowiedzi z serwera',
        AUTHORIZATION_REQUIRED : 'Brak autoryzacji',
        PhoneFormat: 'Niewłaściwy format nr telefonu. Wymagane od 10 do 14 cyfr.',
        SmsCodeFormat: 'Niewłaściwy format kodu sms. Dopuszczalne tylko cyfry.',
        EMAIL_CHANGE_EMAIL_EXISTS: 'Email już jest zarejestrowany',
        TaxNumber: "Niewłaściwy format NIP. Wymagane 10 cyfr bez myślników.",
        PersonalOrCompanyDataForCAA: "Uzupełnij dane osobiste lub dane firmy aby umożliwić wybór",
        UserStateCheck: "Nie można sprawdzić stanu wniosków użytkownika. Błąd: %{error}.",
        allCheckboxesRequired: "Wymagane zaznaczenie wszystkich klauzul."

    }
};

export default messages;



