import React from 'react';
import {regex, required, SimpleForm, TextInput,} from 'react-admin';

import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslatableTypography from "../../TranslatableTypography";
import classnames from "classnames";
import {InfoText} from "../../InfoText";
import {panelStyles} from "./panelStyles";
import DisabledInput from "ra-ui-materialui/esm/input/DisabledInput";
import SanitizedMuiButton from "../../SanitizedMuiButton";
import {WarningText} from "../../WarningText";
import {formValueSelector, isDirty, isValid} from "redux-form";
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {ACCOUNT_PHONE_FORM, PANEL_PHONE} from "../../../logic/settings/AccountConstants";
import {TooltipProxy as Tooltip} from "../../TooltipProxy"

const PhonePanel =
    ({
         classes,
         mySelf,
         expanded,
         handleChange,
         handleSave,
         handlePhoneSave,
         handlePhoneCodeVerify,
         offline,
         disabled,
         translate,
         hasPhone,
         showPhoneCode,
         phoneCode,
         phone,
         isGov,
         isDirty,
         isValid,
         handlePhoneChangeView,
         handlePhoneVerifyView,
     }) =>
    {
        const sendCodeAgainButton =
            <SanitizedMuiButton
                variant="raised"
                color='primary'
                onClick={() => handlePhoneSave({
                    phone: phone,
                    phoneVerified: mySelf.phoneVerified,
                    smsCodeAgain: true
                })}
                disabled={mySelf.smsCodeAgain}
            >
                <TranslatableTypography
                    content={'myroot.dashboard.accountForm.smsCodeAgain'}
                />
            </SanitizedMuiButton>;

        const phoneWithVerificationForm =
            <SimpleForm form={ACCOUNT_PHONE_FORM}
                                                      save={showPhoneCode ? handlePhoneCodeVerify : handlePhoneSave}
                                                      record={{
                                                          phone: mySelf.phone,
                                                          phoneVerified: mySelf.phoneVerified,

                                                      }}
                                                      redirect={false}
                                                      toolbar={disabled || !isDirty || !isValid ? null : undefined}
        >
            {showPhoneCode ?
                (
                    <>
                        <div className={classes.phoneCodeDiv}>
                            <TextInput source={"phoneCode"}
                                       label={translate('myroot.dashboard.accountForm.smsCodeSentAt') + " " + mySelf.smsCodeTime}
                                       validate={[required(), regex(/^[0-9]*$/, 'error.SmsCodeFormat')]}
                                       className={classes.phoneCodeInput}
                                       {...{disabled}}
                            />
                            {!phoneCode &&
                            <>
                                {
                                    mySelf.smsCodeAgain ?
                                        <Tooltip content={'myroot.dashboard.accountForm.smsCodeResendLocked'}>
                                            <div>{sendCodeAgainButton}</div>
                                        </Tooltip>
                                        :
                                        sendCodeAgainButton
                                }
                                <SanitizedMuiButton
                                    variant="raised"
                                    color='primary'
                                    style={{marginTop: "0.5rem"}}
                                    onClick={() => handlePhoneChangeView()}
                                >
                                    <TranslatableTypography
                                        content={'myroot.dashboard.accountForm.changeNumber'}
                                    />
                                </SanitizedMuiButton>
                            </>
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <TextInput source={"phone"}
                                   label={'myroot.dashboard.accountForm.phone'}
                                   placeholder={"48123456789"}
                                   validate={[required(), regex(/^\d{10,14}$/, 'error.PhoneFormat')]}
                                   {...{disabled}}
                        />
                        {
                            (!disabled && !isDirty && isValid && !mySelf.phoneVerified) &&
                            <SanitizedMuiButton
                                variant="raised"
                                color='primary'
                                style={{marginTop: "0.5rem"}}
                                onClick={()=>handlePhoneVerifyView()}
                            >
                                <TranslatableTypography
                                    content={'myroot.dashboard.accountForm.smsVerification'}
                                />
                            </SanitizedMuiButton>
                        }
                    </>
                )
            }

            {
                !isDirty &&
                <DisabledInput source="phoneVerified"
                               label={'myroot.dashboard.accountForm.phoneVerified'}
                               input={mySelf.phoneVerified ? {value: translate('ra.boolean.true')} : {value: translate('ra.boolean.false')}}
                />
            }

        </SimpleForm>;

        const phoneForm =
            <SimpleForm form={ACCOUNT_PHONE_FORM}
                        save={handleSave}
                        record={{
                            phone: mySelf.phone,
                        }}
                        redirect={false}
                        toolbar={disabled || !isDirty || !isValid ? null : undefined}
            >

                        <TextInput source={"phone"}
                                   label={'myroot.dashboard.accountForm.phone'}
                                   placeholder={"48123456789"}
                                   validate={[required(), regex(/^\d{10,14}$/, 'error.PhoneFormat')]}
                                   {...{disabled}}
                        />
            </SimpleForm>;

        return (
            <ExpansionPanel expanded={expanded === PANEL_PHONE}
                            onChange={handleChange(PANEL_PHONE)}
                            className={classnames(
                                classes.expansionPanel,
                                {
                                    [classes.overflowHide]: expanded && expanded !== PANEL_PHONE,
                                    [classes.expansionPanelNotActive]: expanded !== PANEL_PHONE,
                                }
                            )}
                            square={false}
                            id={PANEL_PHONE}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <div className={classes.block}>
                        <Typography component="p" className={classes.heading}>
                            {translate('myroot.dashboard.accountForm.phone')}
                        </Typography>
                        <Typography component="p"
                                    className={classnames(
                                        classes.subheading,
                                        {
                                            [classes.requiredNonFilled]: !hasPhone,
                                        }
                                    )}>
                            {translate('myroot.validation.required')}
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.block}>
                        <Typography component="p" className={classes.subheading}>
                            {showPhoneCode ? `${translate('myroot.dashboard.accountForm.smsCodeDscr')} ${mySelf.phone}` : translate('myroot.dashboard.accountForm.phoneDscr')}
                        </Typography>
                        {
                            isGov ?
                                phoneForm
                                :
                                <>
                                    {phoneWithVerificationForm}
                                    {!mySelf.phoneVerified &&
                                    <WarningText colored
                                                 content={<TranslatableTypography
                                                     content="myroot.auth.sendLockedPhoneNotVerified"/>}
                                                 className={classnames({
                                                     [classes.disabledText]: expanded === PANEL_PHONE,
                                                     [classes.disabledTextHide]: expanded !== PANEL_PHONE,
                                                 })}
                                    />
                                    }

                                    {disabled && !offline &&
                                    <InfoText content={<TranslatableTypography content="myroot.auth.editionLocked"/>}
                                              className={classnames({
                                                  [classes.disabledText]: expanded === PANEL_PHONE,
                                                  [classes.disabledTextHide]: expanded !== PANEL_PHONE,
                                              })}

                                    />
                                    }
                                    {disabled && offline &&
                                    <InfoText
                                        content={<TranslatableTypography content="myroot.auth.editionLockedOffline"/>}
                                        className={classnames({
                                            [classes.disabledText]: expanded === PANEL_PHONE,
                                            [classes.disabledTextHide]: expanded !== PANEL_PHONE,
                                        })}

                                    />
                                    }
                                </>
                        }


                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
};


const mapStateToProps = state => {
    return {
        phone: formValueSelector(ACCOUNT_PHONE_FORM)(state, 'phone'),
        phoneCode: formValueSelector(ACCOUNT_PHONE_FORM)(state, 'phoneCode'),
        isDirty: isDirty(ACCOUNT_PHONE_FORM)(state),
        isValid: isValid(ACCOUNT_PHONE_FORM)(state),
    }
};

const enhance = compose(
    withStyles(panelStyles),
    connect(mapStateToProps, null),
);

export default enhance(PhonePanel);