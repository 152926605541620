//damn2, temp solution
//fixme styling, connect translate
import {MissionV2Statuses} from "../../../../LogicV1Redux/constants/MissionsV2Enums";
import Typography from '@material-ui/core/Typography';
import React from "react";
import TranslatableTypography from '../../TranslatableTypography'
import SanitizedMuiButton from "./MissionAtcUserToolbar";
import {Labeled} from "ra-ui-materialui";
import moment from 'moment';

const OldAtcMainInfo = ({classes, atcMsg, isRejected, style}) => {
    if (!atcMsg) return null;
    const approvalStatusLabel = (isRejected)
        ?'myroot.zoneConflictResolver.unitApprovalRejected'
        :'myroot.zoneConflictResolver.unitApprovalConfirmed';

    const time = moment(atcMsg.timestamp*1000).format('YY-MM-DD HH:mm');
    return (
        <>
            <div className={classes.hasOwnProperty('root') ? classes.root : undefined}>
                <TranslatableTypography
                    variant="caption"
                    content={approvalStatusLabel}/>
                <Typography style={style} variant="body2">
                    {time}
                </Typography>
            </div>
            <div className={classes.hasOwnProperty('root') ? classes.root : undefined}>
                <TranslatableTypography
                    variant="caption"
                    content={'myroot.zoneConflictResolver.unitName'}/>
                <TranslatableTypography
                    variant="body2"
                    content={'not_translated'}
                    options={{'_': atcMsg.issuerUnitName}}
                />
            </div>
        </>
    )
};

//TODO refactor to separate component - used also in CaaMsgBlock
export const OldStatusMsg = ({classes, header, headerOptions, style, msg}) => {
    if (!msg) return null;
    return (
        <div className={classes.hasOwnProperty('root') ? classes.root : undefined}>
            <TranslatableTypography
                variant="caption"
                content={header}
                options={headerOptions}
            />
            <Typography style={style} variant="body2">
                {msg}
            </Typography>
        </div>
    )
};

//TODO refactor to separate component- used also in CaaMsgBlock
export const OldStatusIssuer = ({classes, issuerEmail}) => {
    if (!issuerEmail) return null;
    const linkStyle = {color: "inherit"};
    return (
        <div className={classes.hasOwnProperty('root') ? classes.root : undefined}>
            <TranslatableTypography variant="caption"
                                    content={'myroot.zoneConflictResolver.issuedBy'} />
            <Typography>
                <a href={"mailto:" + issuerEmail}
                   style={linkStyle}>{issuerEmail}</a>
            </Typography>
            <br/>
        </div>
    )
};

function status2label(isRejected) {
    return (isRejected)
        ?'myroot.zoneConflictResolver.rejectDialogDefaultText'
        :'myroot.zoneConflictResolver.acceptDialogLabel';
}

//TODO refactor to separate component - exists also as CaaMsgBlock
//old messageBlock, only pansa was able to post accepts/rejects
const AtcMsgV0 = (props) => {
    //console.log('=======atc v0 msg props', props);
    const {record, classes = {}} = props;

    //dang -> rejected may switcht to planned

    const isRejected = ((record.status === MissionV2Statuses.REJECTED) || (record.status === MissionV2Statuses.PLANNED));

    const header = status2label(isRejected);

    const style = (isRejected)?{color:"tomato", whiteSpace: "pre-wrap"}:{whiteSpace: "pre-wrap"};

    if ((isRejected || (record.status === MissionV2Statuses.ACCEPTED))
        && record.etc)
        return (
            <>
                <OldStatusMsg classes={classes} header={header} msg={record.etc.atcStatusMsg} style={style}/>
                {/*remember that operator don't have access to gov db data. */}
                <OldStatusIssuer classes={classes} issuerEmail={record.etc.atcStatusIssuerEmail}/>
            </>
        );
    else return null;
};


const SingleAtcMsgV1 = (props) => {
    const {atcMsg, classes = {}} = props;

    //console.log ('======atcv1 singleatcmg', atcMsg);

    // issuerEmail: "drony@pansa.pl"
    // issuerUnitId: "f5c234c7-1c12-463a-940e-c4d679fefadc"
    // issuerUnitName: "Testowy LAU"
    // msg: "bo tak"
    // status: "rejected"
    // timestamp: 1551325793.135

    const isRejected = (atcMsg.status === MissionV2Statuses.REJECTED); //per msg now.. (not per mission)
    const header = status2label(isRejected);
    const style = (isRejected)?{color:"tomato", whiteSpace: "pre-wrap"}:{whiteSpace: "pre-wrap"};

    return (
    <>
        <OldAtcMainInfo classes={classes} header={header}
                        atcMsg={atcMsg} isRejected={isRejected} style={style} />
        <OldStatusMsg classes={classes} header={header} msg={atcMsg.msg} style={style}/>
        {/*remember that operator don't have access to gov db data. */}
        <OldStatusIssuer classes={classes} issuerEmail={atcMsg.issuerEmail}/>
    </>
    )
};

//madrid demo, multiple users can accept/reject
const AtcMsgsV1 = (props) => {
    //console.log('=======atcv1 msg props', props);
    const {record, classes = {}} = props;

    const govMsgs = record.govMsgs || [];
    //console.log('=======atcv1 govMsgs', govMsgs);

    if (govMsgs.length === 0) {
        return null;
    }

    const atcInfos = [];

    for (let i=govMsgs.length-1; i >= 0; i--) {
    //for (let i=0; i<govMsgs.length; i++) {
        atcInfos.push(<SingleAtcMsgV1 key={i}  atcMsg={govMsgs[i]} classes={classes}/>);
    }

    return (
        <>
            {atcInfos}
        </>
    );
};


//version switcher, really old stuff, check on prod if etc.atcStatusIssuerEmail is somewhere used..
const AtcMsgBlock = (props) => {
    const {record} = props;

    if (record.etc && record.etc.atcStatusIssuerEmail) {
        return <AtcMsgV0 {...props} />
    } else if (record.govMsgs) {
        return <AtcMsgsV1 {...props} />
    }

    return null;

};

export default AtcMsgBlock
