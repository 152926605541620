//old notes, to review
//force JSON validation (object or array)!, but enable null if not required
//see also
//https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try/3710226


import React from "react";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import { Field } from 'redux-form';
import get from "lodash.get";
import isPlainObject from "lodash.isobjectlike";

//some attempt to create custom textarea div, unused now
const styles = {
    textarea: {
        "-moz-appearance": "textfield-multiline",
        "-webkit-appearance": "textarea",
        "border": "1px solid gray",
        //"font": "medium -moz-fixed",
        //"font": "-webkit-small-control",
        "height": "28px",
        "overflow": "auto",
        "padding": "2px",
        "resize": "none",
        "width": "400px"
    }
};

//only true (plain) json objects, see lodash docs.
export function jsonObjectValidation(value, allValues, props) {
    console.log('==json validation', value);
    if (!isPlainObject(value)) {
        return props.translate('myroot.validation.JSONObject');
    }
    return undefined;
}

//allow null as a value AND plain object
export function nullableJSONObjectValidation(value, allValues, props) {
    console.log('==json validation', value);
    if (value !== null && !isPlainObject(value)) {
        return props.translate('myroot.validation.nullableJSONObject');
    }
    console.log('==json validation ok', value);

    return undefined;
}

//fixme -> connect theme styling
const renderJSONField = (field) => (
    <div className="json-field">
        <textarea {...field.input} style={{width:"100%", minHeight: '100px'}}/>
        {field.meta.touched && field.meta.error &&
        <span className="error" style={{color:"red"}}> {field.meta.error}</span>}
    </div>
);


//have to be validated later
export const JSONField = (props) => {

    function format(v) {
        console.log('== format in', v);
        //let isValid = true;
        if (v === null || v === "") {
            return "";
        }
        const isString = (typeof v === 'string' || v instanceof String);
        let ret = v;
        try {
            ret = (isString)?v:JSON.stringify(v);
        } catch (e) {
            //isValid = false;
        }

        return ret;
    }

    function parse(v) {
        console.log('== parse in', v);

        if (v === null || v === "") return null;
        try {
            return JSON.parse(v);
        } catch (e) {
            return v;
        }
    }

    // function onChange(event) {
    //     //event.preventDefault()
    // }

    const {record, source} = props;
    //const name = `__${source}`;
    console.log('==jsf prpos', props);
    const sourceValue = get(record, source);
    console.log('==jsf val', sourceValue);

    return (

        <Field name={source}
               component={renderJSONField}
               type="text"
               format={format}
               parse={parse}
               //onChange={onChange}
               placeholder=""
               validate={props.validate}
        />
    )
};

//fixme label/field translation flow, somehow field translation is not attached now.
export const JSONInput = ({label, ...props}) =>  {
    label = (label)?label:props.source;
    return (
    <Labeled label={label}>
        <JSONField {...props}/>
    </Labeled>
)};
